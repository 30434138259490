import { Component, OnInit } from '@angular/core';

/**
 * Component to show loader. This is used on page load as well as when a HTTP call is made.
 */
@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {

}
