<nav class="navbar navbar-expand-lg navbar-dark header-content">
  <a class="navbar-brand" routerLink="/home/dashboard">Config Studio</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-expanded="false"
    aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
    aria-controls="navbarToggler">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarToggler" [ngbCollapse]="isCollapsed">
    <!-- <div class="form-group"> -->
      <!-- <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed"
        [(ngModel)]="model" [ngbTypeahead]="search" placeholder="Looking for something. Start typing" /> -->
      <!-- <small *ngIf="searching" class="form-text text-muted">searching...</small> -->
      <!-- <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div> -->
    <!-- </div> -->
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0"> 
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="home/dashboard" routerLinkActive="active">Home</a>

      </li>  -->
      <li class="nav-item">
        <a class="nav-link" routerLink="scheduler/dashboard" routerLinkActive="active">Schedulers</a>
      </li>
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="navbarDropdown1"> 
          Manage 
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem routerLink="account/dashboard" routerLinkActive="active">Account</a>
          <a ngbDropdownItem routerLink="portfolio/dashboard" routerLinkActive="active">Portfolio</a>
          <a ngbDropdownItem routerLink="program/dashboard" routerLinkActive="active">Program</a>
          <a ngbDropdownItem routerLink="project/dashboard" routerLinkActive="active">Project</a>
          <!-- <a ngbDropdownItem routerLink="teams/dashboard" routerLinkActive="active">Teams</a>
          <a ngbDropdownItem routerLink="tools/dashboard" routerLinkActive="active">Tools</a> -->
        </div>
      </li>  
      <li class="nav-item">
        <a class="nav-link" routerLink="users/dashboard" routerLinkActive="active">Users</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="sync-job-auth/dashboard" routerLinkActive="active">User_Auth</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/help" routerLinkActive="active">Help</a>
      </li>
      <li class="nav-item">
        <a class="nav-link ml-2 mr-3" href="#"><i class="far fa-bell"></i></a>
      </li>
      <li class="nav-item profile-menu" ngbDropdown>
        <a class="img-user"><img [src]="userDetail?.userAvatar" alt=""></a>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">{{userDetail?.userFullname |
          titlecase}}</a>
        <div ngbDropdownMenu aria-labelledby="profileDropdown" class="dropdown-menu dropdown-menu-right">
          <a ngbDropdownItem class="d-flex" style="cursor: pointer;" routerLink="/profile">My Profile
            <i class="far fa-address-card ml-auto"></i></a>
          <div class="dropdown-divider"></div>
          <a routerLink="home/locked" ngbDropdownItem class="d-flex" style="cursor: pointer;">Lock Screen
            <i class="fas fa-key ml-auto"></i></a>
          <a (click)="logout()" ngbDropdownItem class="d-flex" style="cursor: pointer;">Sign
            Out
            <i class="fas fa-sign-out-alt ml-auto"></i></a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<app-toast *ngIf="showToast" [headerContent]="toastHeader" [bodyContent]="toastBody" toastType="warning"
  [toastAutoClose]="false"></app-toast>