import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AuthResponse } from '../interface/auth-response';
import { AuthToken } from '../interface/auth-token';
import { UserDetail } from '../interface/user-detail';

/**
 * Service to manage user related operations in this application.
 */
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  /**
   * This function is used for retrieving the saved user details either from Local Storage or Session Storage.
   * @returns 
   */
  getUserDetail(): UserDetail {
    let userDetail: UserDetail = {};
    if (localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) {
      userDetail = JSON.parse(atob(localStorage.getItem('user') || '{}'));
    } else if (sessionStorage.getItem('user') != undefined && sessionStorage.getItem('user') != null) {
      userDetail = JSON.parse(atob(sessionStorage.getItem('user') || '{}'));
    }
    return userDetail;
  }

  /**
   * This function is used to store user details in Local storage if storeLocal is TRUE else to Session storage.
   * @param userDetail 
   * @param storeLocal 
   */
  updateUserDetail(userDetail: UserDetail, storeLocal?: boolean) {
    if ((localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) || storeLocal) {
      localStorage.setItem('user', btoa(JSON.stringify(userDetail)));
      sessionStorage.clear();
    } else if ((sessionStorage.getItem('user') != undefined && sessionStorage.getItem('user') != null) || !storeLocal) {
      sessionStorage.setItem('user', btoa(JSON.stringify(userDetail)));
      localStorage.clear();
    }
  }

  /**
   * Function to store user data into local or session storage once the user token is issued by auth service.
   * @param authResponse 
   * @param rememberMe 
   */
  login(authResponse: AuthResponse, rememberMe: boolean) {
    let decodedToken: AuthToken = JSON.parse(
      atob(authResponse?.data?.token?.substring(authResponse?.data?.token?.indexOf('.') + 1,
        authResponse?.data?.token?.lastIndexOf('.')).replace('_', '/') || '{}'));

    let userDetail: UserDetail = {};
    if (this.getUserDetail().token == undefined || this.getUserDetail().token == null) {
      userDetail.token = authResponse?.data?.token;
      userDetail.tokenExpiry = decodedToken.exp;
      userDetail.userFullname = decodedToken.sub;
      userDetail.userEmail = decodedToken.user;
      userDetail.userId = decodedToken["user-id"].toString(),
      userDetail.userAvatar = decodedToken.avatar;
      userDetail.userCurrentRole = decodedToken["def-role"];
      userDetail.userRoles = [decodedToken.auth].join();
      userDetail.userAcls = authResponse?.data?.acl;
    } else {
      userDetail = this.getUserDetail();
      userDetail.token = authResponse?.data?.token;
      userDetail.tokenExpiry = decodedToken.exp;
    }
    this.updateUserDetail(userDetail, rememberMe);
  }

  /**
   * Logout a user from the system by deleting its entries both from Local & Session storage
   */
  logout(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  /**
   * Get the time remaining for the user token that is currently saved. It returns time in seconds.
   * @returns 
   */
  userLoginTimeRemain(): number {
    return ((this.getUserDetail().tokenExpiry || 0) - Math.trunc(moment.now() / 1000));
  }

  /**
   * Save the current page of the user where he is currently. This is used to return to the same page incase session
   * expires and the user needs to login.
   * @param pageRoute 
   */
  setCurrentPageUrl(pageRoute: string): void {
    if (localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) {
      localStorage.setItem('currentPage', pageRoute);
    } else if (sessionStorage.getItem('user') != undefined && sessionStorage.getItem('user') != null) {
      sessionStorage.setItem('currentPage', pageRoute);
    }
  }

  /**
   * Get the last location of the user from the system.
   * @returns 
   */
  getLastPageUrl(): string {
    if (localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) {
      return localStorage.getItem('currentPage') || '';
    } else if (sessionStorage.getItem('user') != undefined && sessionStorage.getItem('user') != null) {
      return sessionStorage.getItem('currentPage') || '';
    } else {
      return '';
    }
  }
}
