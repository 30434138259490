import { Component, Input } from '@angular/core';

/**
 * Component to show notifications as Toast by different components in the app.
 */
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  /**
   * Input to capture Toast Header.
   */
  @Input() headerContent: string = '';
  /**
   * Input to capture Toast Body
   */
  @Input() bodyContent: string = '';
  /**
   * Input to capture Toast Type. Possible values:
   * success, info, warning, danger, primary, secondary, default, dark
   */
  @Input() toastType: string = 'default';
  /**
   * Input to capture wheather to auto close the toast or not.
   */
  @Input() toastAutoClose: boolean = true;

  /**
   * Variable to initially show the toast when called from other components.
   */
  show: boolean = true;

  /**
   * Function to close the toast after 3 seconds.
   */
  close() {
    this.show = false;
    setTimeout(() => this.show = true, 3000);
  }

}
