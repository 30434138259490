import { Component }  from '@angular/core';
import { formatDate } from '@angular/common';

/**
 * Footer component to be displayed on all pages.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  /**
   * To store current year for copyright statement.
   */
  currentYear = formatDate(new Date(), 'yyyy', 'en-US');
  /**
   * To display the timestamp when the page was loaded.
   */
  currentDateTime: string = formatDate(new Date(), "dd-MMM hh:mm aa", 'en-US');

}
