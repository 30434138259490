import { Component }                          from '@angular/core';
import { Router }                             from '@angular/router';
import { NgbModal }                           from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction }   from 'rxjs';
import { UserDetail }                         from 'src/app/core/interface/user-detail';
import { UserAuthService }                    from 'src/app/core/service/user-auth.service';
import { catchError, debounceTime, 
         distinctUntilChanged, switchMap, 
         tap }                                from 'rxjs/operators';

/**
 * Component to add common header on top of the pages.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  /**
   * To toggle navbar on small screens
   */
  isCollapsed: boolean = true;
  /**
   * Interval after which the token validity is checked.
   */
  loadDataInterval: any;
  /**
   * To stop interval if the token has expired.
   */
  loginExpire: boolean = false;
  /**
   * To stop interval if the token has expired.
   */
   showToast: boolean = false;
  /**
   * To hold toast body content
   */
  toastBody: string = '';
  /**
   * To create toast header
   */
  toastHeader: string = '';
  /**
   * To store user details from user service
   */
  userDetail: UserDetail = {};

  // For autocomplete search
  model: any;
  searching = false;
  searchFailed = false;

  /**
   * Constructor for
   * @param router 
   * @param userAuth 
   * @param modalService 
   * @param _service 
   */
  constructor(
    private router: Router,
    private userAuth: UserAuthService,
    private modalService: NgbModal,
  ) {
    this.userDetail = this.userAuth.getUserDetail();
    let tokenTime: number = this.userAuth.userLoginTimeRemain();
      this.loadDataInterval = setInterval(() => {
        if (tokenTime <= 600 && tokenTime > -1) {
          let minutesT = Math.floor(tokenTime / 60);
          this.showToast = true;
          this.toastHeader = 'Attention!!!';
          this.toastBody = 'Your session will expire in ' + minutesT + ' minutes. you will have to login again to continue.';
          if (this.loginExpire) {
            this.showToast = false;
            clearInterval(this.loadDataInterval);
          }
        } else if(tokenTime < 0) {
          this.loginExpire = true;
        }
      }, 60000);
  }

  /**
   * Logout user from the system and clear 
   * Intervals
   * Redirect to login page
   */
  logout() {
    this.showToast = false;
    clearInterval(this.loadDataInterval);
    this.userAuth.logout();
    this.router.navigate(['/home/login']);
  }

}
