import { NgModule }                   from '@angular/core';
import { FormsModule }                from '@angular/forms';
import { CommonModule }               from '@angular/common';
import { RouterModule }               from '@angular/router';
import { NgbModule }                  from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent }            from './layout/header/header.component';
import { FooterComponent }            from './layout/footer/footer.component';
import { ToastComponent }             from './component/toast/toast.component';
import { SidebarComponent }           from './layout/sidebar/sidebar.component';
import { PageLoaderComponent }        from './component/page-loader/page-loader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    PageLoaderComponent,
    ToastComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    CommonModule,
    RouterModule
  ],
  exports: [
    PageLoaderComponent,
    HeaderComponent,
    FooterComponent,
    ToastComponent,
  ]
})
export class SharedModule { }
