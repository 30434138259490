import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserAuthService } from './core/service/user-auth.service';
import { Event as NavigationEvent } from '@angular/router';

/**
 * App component. Loads the complete application.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * Set page title.
   */
  title = 'config-studio';
  /**
   * Check if the user is still loggedin. This is to make header appear/ disappear
   */
  isUserLoggedIn: boolean = false;

  /**
   * Constructor for
   * @param router 
   * @param userAuth 
   */
  constructor(
    private router: Router,
    private userAuth: UserAuthService
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((navEnd: NavigationEvent) => {
        this.isUserLoggedIn = false;
        if (this.userAuth.userLoginTimeRemain() > 0) {
          this.isUserLoggedIn = true;
        }
      });
  }
}
