import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./page/general/general.module').then(m => m.GeneralModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./page/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./page/portfolio/portfolio.module').then(m => m.PortfolioModule)
  },
  {
    path: 'program',
    loadChildren: () => import('./page/program/program.module').then(m => m.ProgramModule)
  },
  {
    path: 'project',
    loadChildren: () => import('./page/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'teams',
    loadChildren: () => import('./page/teams/teams.module').then(m => m.TeamsModule)
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./page/scheduler/scheduler.module').then(m => m.SchedulerModule)
  },
  {
    path: 'tools',
    loadChildren: () => import('./page/tools/tools.module').then(m => m.ToolsModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./page/users/users.module').then(m => m.UsersModule)
  },
  {
    path: '**',
    loadChildren: () => import('./page/general/general.module').then(m => m.GeneralModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
