import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAuthService } from '../service/user-auth.service';
import { UserDetail } from '../interface/user-detail';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpCallInterceptor implements HttpInterceptor {

  constructor(
    private userAuth: UserAuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.userAuth.userLoginTimeRemain() != undefined && this.userAuth.userLoginTimeRemain() != null) {
      if (this.userAuth.userLoginTimeRemain() > 0) {
        let userDetail: UserDetail = this.userAuth.getUserDetail();
        const cloned = request.clone({
          headers: request.headers
            .set('Authorization', 'Bearer ' + userDetail.token)
        });
        return next.handle(cloned).pipe(tap(() => { }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              this.router.navigate(['/home/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
            }
          }
        }));
      } else {
        this.userAuth.logout();
        // this.router.navigate(['/home/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }
}
