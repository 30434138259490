import { HttpClientModule, HTTP_INTERCEPTORS }  from '@angular/common/http';
import { NgModule }                             from '@angular/core';
import { BrowserModule }                        from '@angular/platform-browser';
import { LoggerModule, NgxLoggerLevel }         from 'ngx-logger';
import { AppRoutingModule }                     from './app-routing.module';
import { AppComponent }                         from './app.component';
import { HttpCallInterceptor }                  from './core/interceptor/http-call.interceptor';
import { SharedModule }                         from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LoggerModule.forRoot({
      // serverLoggingUrl: '',
      level: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCallInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
