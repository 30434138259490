
export const environment = {
  production:                   false,
  apiTimeout:                   60000,
  authenticateUser:             '/api/v3/authenticate',
  getJobList:                   '/api/v1/stats/summary',
  getAccountList:               '/api/v1/account',
  createAccount:                '/api/v1/account',
  updateAccount:                '/api/v1/account',
  deleteAccount:                '/api/v1/account/~replaceMe~',
  getPortfolioList:             '/api/v1/portfolio',
  createPortfolio:              '/api/v1/portfolio',
  updatePortfolio:              '/api/v1/portfolio',  
  deletePortfolio:              '/api/v1/portfolio/~replaceMe~',
  getProgramList:               '/api/v1/program', 
  createProgram:                '/api/v1/program', 
  updateProgram:                '/api/v1/program',
  deleteProgram:                '/api/v1/program/~replaceMe~',
  getProjectList:               '/api/v1/project',
  createProject:                '/api/v1/project',
  updateProject:                '/api/v1/project',
  deleteProject:                '/api/v1/project/~replaceMe~',
  getTeamsList:                 '/api/v1/projectTeams',
  createTeams:                  '/api/v1/projectTeams',
  updateTeams:                  '/api/v1/projectTeams',
  deleteTeams:                  '/api/v1/projectTeams/~replaceMe~',
getExistingToolList:             '/api/v1/tool',
activateToolAccount:             '/api/v1/tool',
deactivateToolAccount:            '/api/v1/tool',
updateTool:                        '/api/v1/tool',
createTool:                        '/api/v1/tool',
getSyncJobAuthList:               '/api/v1/syncjobauth',
updateSyncJobAuth:                '/api/v1/syncjobauth',
createSyncJobAuth:                '/api/v1/syncjobauth',   
  getExistingJobList:           '/api/v1/job/list',
  getExistingJobEntity:         '/api/v1/job/~replaceMe~',
  createJobEntity:              '/api/v1/job',
  updateExistingJobEntity:      '/api/v1/job',
  deleteExistingJobAllFields:   '/api/v1/job/field/~replaceMe~',
  deleteExistingJobAllParams:   '/api/v1/job/param/~replaceMe~',
  deleteExistingJob:            '/api/v1/job/~replaceMe~',
  getExistingJobParam:          '/api/v1/job/param/~replaceMe~',
  createExistingJobParam:       '/api/v1/job/param',
  updateExistingJobParam:       '/api/v1/job/param',
  deleteExistingJobParam:       '/api/v1/job/param?param-id=~replaceMe~',
  getExistingJobField:          '/api/v1/job/field/~replaceMe~',
  createExistingJobField:       '/api/v1/job/field',
  updateExistingJobField:       '/api/v1/job/field',
  deleteExistingJobField:       '/api/v1/job/field?field-id=~replaceMe~',
  getExistingJobLogs:           '/api/v1/job/logs/~replaceme~',
  getTemplateJobList:           '/api/v1/job/template/list',
  getTemplateJobEntity:         '/api/v1/job/template/~replaceMe~',
  getTemplateJobParam:          '/api/v1/job/template/~replaceMe~/param',
  getTemplateJobField:          '/api/v1/job/template/~replaceMe~/field-map',
  runExistingJob:               '/schedule/job/~replaceMe~',
  refreshJobCache:              '/cache/refresh',
  refreshAclCache:              '/acl/refresh',
  refreshDmCache:               '/dm/refresh',
  getProjectExistingToolsList:  '/api/v1/project/~replaceMe~/tools',
  getProjectAuthList:           '/api/v1/metadata/~replaceMe~/authentication/list',
  getExistingUserList:          '/api/v1/user',
  activateUserAccount:         '/api/v1/user/~replaceMe~/activeORdeactive',
  // deactivateUserAccount:        '/api/v1/user/~replaceMe~/deactivate',
  getExistingUserAccessList:    '/api/v1/user/~replaceMe~/access',
  addNewAccessToUserId:         '/api/v1/user/~replaceMe~/access',
  activateAccessForUserId:      '/api/v1/user/~replaceMe~/access/{access-id}/activateORdeactivate',
  // deactivateAccessForUserId:    '/api/v1/user/~replaceMe~/access/~accessId~/deactivate',
  addNewUserAccount:            '/api/v1/user',
  getUserRolesList:             '/api/v1/user/roles',
  downloadExcelFormat:          '/api/v1/user/downloadExcelFormat',
  uploadExcelForUsers:          '/api/v1/user/uploadUsers'
};